import React from 'react';

const useInsertionEffect =
	typeof window !== 'undefined'
		? React.useInsertionEffect || React.useLayoutEffect
		: () => {};

export function useEvent<TCallback extends (...args: any[]) => any>(
	callback: TCallback,
): TCallback {
	const latestRef = React.useRef<TCallback>(null as any);
	useInsertionEffect(() => {
		latestRef.current = callback;
	}, [callback]);

	const stableRef = React.useRef<TCallback>(null as any);
	if (!stableRef.current) {
		stableRef.current = function (this: any) {
			return latestRef.current.apply(this, arguments as any);
		} as TCallback;
	}

	return stableRef.current;
}
